body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a{
  color: unset !important;
}
a:visited{
  color: unset;
}
/*===========================
     CHAT BOOT MESSENGER
   ===========================*/
#Smallchat .Messages,
#Smallchat .Messages_list {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.chat_close_icon {
  cursor: pointer;
  color: black;
  font-size: 25px;
  position: fixed;
  right: 12px;
  z-index: 9;
}
.chat_on {
  position: fixed;
  z-index: 10;
  width: 50px;
  height: 50px;
  right: 25px;
  bottom: 20px;
  /* background-color: #8a57cf; */
  background-color: rgb(255, 255, 255);
  color: #fff;
  border-radius: 50%;
  text-align: center;
  padding: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  cursor: pointer;
  display: block;
  font-size: 30px;
}
.chat_on_icon {
  color: #fff;
  font-size: 25px;
  text-align: center;
}
/*
#Smallchat,#Smallchat * {
 box-sizing:border-box; 
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale;
 -webkit-tap-highlight-color:transparent
}
*/
#Smallchat .Layout {
  pointer-events: auto;
  box-sizing: content-box !important;
  z-index: 999999999;
  position: fixed;
  bottom: 20px;
  min-width: 50px;
  max-width: 300px;
  max-height: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-radius: 50px;
  box-shadow: 5px 0 20px 5px rgba(0, 0, 0, 0.1);
  -webkit-animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  -webkit-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
}

#Smallchat .Layout-right {
  right: 20px;
}

#Smallchat .Layout-open {
  overflow: hidden;
  min-width: 300px;
  max-width: 300px;
  height: 500px;
  max-height: 500px;
  border-radius: 10px;
  color: #fff;
  -webkit-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
}

#Smallchat .Layout-expand {
  height: 500px;
  min-height: 500px;
  display: none;
}
#Smallchat .Layout-mobile {
  bottom: 10px;
}
#Smallchat .Layout-mobile.Layout-open {
  width: calc(100% - 20px);
  min-width: calc(100% - 20px);
}
#Smallchat .Layout-mobile.Layout-expand {
  bottom: 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  border-radius: 0 !important;
}
@-webkit-keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
#Smallchat .Messenger_messenger {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 300px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
#Smallchat .Messenger_header,
#Smallchat .Messenger_messenger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
#Smallchat .Messenger_header {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 40px;
  height: 40px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

#Smallchat .Messenger_header h4 {
  opacity: 0;
  font-size: 16px;
  -webkit-animation: slidein 0.15s 0.3s;
  animation: slidein 0.15s 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#Smallchat .Messenger_prompt {
  margin: 0;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@-webkit-keyframes slidein {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slidein {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
#Smallchat .Messenger_content {
  height: 450px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
}
#Smallchat .Messages {
  position: relative;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
}

#Smallchat .Input {
  position: relative;
  width: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-top: 17px;
  padding-bottom: 15px;
  color: #96aab4;
  background-color: #fff;
  border-top: 1px solid #e6ebea;
}
#Smallchat .Input-blank .Input_field {
  max-height: 20px;
}
#Smallchat .Input_field {
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  padding: 0;
  padding-right: 0px;
  padding-left: 0px;
  padding-left: 20px;
  padding-right: 75px;
  background-color: transparent;
  font-size: 16px;
  line-height: 20px;
  min-height: 20px !important;
}
#Smallchat .Input_button-emoji {
  right: 45px;
}
#Smallchat .Input_button {
  position: absolute;
  bottom: 55px;
  width: 25px;
  height: 25px;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
#Smallchat .Input_button-send {
  right: 15px;
}
#Smallchat .Input-emoji .Input_button-emoji .Icon,
#Smallchat .Input_button:hover .Icon {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
#Smallchat .Input-emoji .Input_button-emoji .Icon path,
#Smallchat .Input_button:hover .Icon path {
  fill: #2c2c46;
}

.userAvatar {
  animation: animateElement linear 0.3s;
  /* animation-iteration-count: 1; */
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;

  margin-right: 5px;
  border: 2px solid rgb(63, 101, 181);
  float: right;
  margin-left: 100px;
}

.botAvatar {
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;

  margin-left: 5px;
  border: 2px solid rgb(63, 101, 181);
  float: left;
}

.botMsg {
  float: left;
  margin-top: 5px;
  background: #4285f4;
  box-shadow: 2px 5px 5px 1px #efeef5;
  border: 1px solid #4285f4;
  margin-left: 0.5em;
  padding: 0.6em 1em;
  border-radius: 1.5em;
  max-width: 75%;
  min-width: 25%;
  font-size: 12px;
  word-wrap: break-word;
  color: #fff;
}

.userMsg {
  animation: animateElement linear 0.2s;
  /* animation-iteration-count: 1; */
  margin-top: 5px;
  word-wrap: break-word;
  padding: 0.6em 1em;
  float: right;
  /* margin-right: 0.5em; */
  background: #4285f4;
  border: 1px solid #4285f4;
  color: #fff;
  border-radius: 1.5em;
  margin-bottom: 0.15em;
  font-size: 12px;
  max-width: 75%;
  min-width: 25%;
  line-height: 1.5em;
  margin-left: 100px;
}

.clearfix {
  margin-top: 2px;
  margin-bottom: 2px;
}

.love {
  text-align: center;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  color: rgb(0, 0, 0);
  font-style: italic;
}

.iconic {
  width: 29px;
  height: 29px;
  padding: -23px;
  padding-bottom: 17px;
}