@import url(https://fonts.cdnfonts.com/css/product-sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a{
  color: unset !important;
}
a:visited{
  color: unset;
}
/*===========================
     CHAT BOOT MESSENGER
   ===========================*/
#Smallchat .Messages,
#Smallchat .Messages_list {
  flex-grow: 1;
}
.chat_close_icon {
  cursor: pointer;
  color: black;
  font-size: 25px;
  position: fixed;
  right: 12px;
  z-index: 9;
}
.chat_on {
  position: fixed;
  z-index: 10;
  width: 50px;
  height: 50px;
  right: 25px;
  bottom: 20px;
  /* background-color: #8a57cf; */
  background-color: rgb(255, 255, 255);
  color: #fff;
  border-radius: 50%;
  text-align: center;
  padding: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  cursor: pointer;
  display: block;
  font-size: 30px;
}
.chat_on_icon {
  color: #fff;
  font-size: 25px;
  text-align: center;
}
/*
#Smallchat,#Smallchat * {
 box-sizing:border-box; 
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale;
 -webkit-tap-highlight-color:transparent
}
*/
#Smallchat .Layout {
  pointer-events: auto;
  box-sizing: content-box !important;
  z-index: 999999999;
  position: fixed;
  bottom: 20px;
  min-width: 50px;
  max-width: 300px;
  max-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 50px;
  box-shadow: 5px 0 20px 5px rgba(0, 0, 0, 0.1);
  -webkit-animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
}

#Smallchat .Layout-right {
  right: 20px;
}

#Smallchat .Layout-open {
  overflow: hidden;
  min-width: 300px;
  max-width: 300px;
  height: 500px;
  max-height: 500px;
  border-radius: 10px;
  color: #fff;
  transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
}

#Smallchat .Layout-expand {
  height: 500px;
  min-height: 500px;
  display: none;
}
#Smallchat .Layout-mobile {
  bottom: 10px;
}
#Smallchat .Layout-mobile.Layout-open {
  width: calc(100% - 20px);
  min-width: calc(100% - 20px);
}
#Smallchat .Layout-mobile.Layout-expand {
  bottom: 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  border-radius: 0 !important;
}
@-webkit-keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
#Smallchat .Messenger_messenger {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 300px;
  flex-direction: column;
}
#Smallchat .Messenger_header,
#Smallchat .Messenger_messenger {
  display: flex;
}
#Smallchat .Messenger_header {
  align-items: center;
  padding-left: 10px;
  padding-right: 40px;
  height: 40px;
  flex-shrink: 0;
}

#Smallchat .Messenger_header h4 {
  opacity: 0;
  font-size: 16px;
  -webkit-animation: slidein 0.15s 0.3s;
  animation: slidein 0.15s 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#Smallchat .Messenger_prompt {
  margin: 0;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@-webkit-keyframes slidein {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slidein {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
#Smallchat .Messenger_content {
  height: 450px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
#Smallchat .Messages {
  position: relative;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
}

#Smallchat .Input {
  position: relative;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 17px;
  padding-bottom: 15px;
  color: #96aab4;
  background-color: #fff;
  border-top: 1px solid #e6ebea;
}
#Smallchat .Input-blank .Input_field {
  max-height: 20px;
}
#Smallchat .Input_field {
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  padding: 0;
  padding-right: 0px;
  padding-left: 0px;
  padding-left: 20px;
  padding-right: 75px;
  background-color: transparent;
  font-size: 16px;
  line-height: 20px;
  min-height: 20px !important;
}
#Smallchat .Input_button-emoji {
  right: 45px;
}
#Smallchat .Input_button {
  position: absolute;
  bottom: 55px;
  width: 25px;
  height: 25px;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
#Smallchat .Input_button-send {
  right: 15px;
}
#Smallchat .Input-emoji .Input_button-emoji .Icon,
#Smallchat .Input_button:hover .Icon {
  transform: scale(1.1);
  transition: all 0.1s ease-in-out;
}
#Smallchat .Input-emoji .Input_button-emoji .Icon path,
#Smallchat .Input_button:hover .Icon path {
  fill: #2c2c46;
}

.userAvatar {
  -webkit-animation: animateElement linear 0.3s;
          animation: animateElement linear 0.3s;
  /* animation-iteration-count: 1; */
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;

  margin-right: 5px;
  border: 2px solid rgb(63, 101, 181);
  float: right;
  margin-left: 100px;
}

.botAvatar {
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;

  margin-left: 5px;
  border: 2px solid rgb(63, 101, 181);
  float: left;
}

.botMsg {
  float: left;
  margin-top: 5px;
  background: #4285f4;
  box-shadow: 2px 5px 5px 1px #efeef5;
  border: 1px solid #4285f4;
  margin-left: 0.5em;
  padding: 0.6em 1em;
  border-radius: 1.5em;
  max-width: 75%;
  min-width: 25%;
  font-size: 12px;
  word-wrap: break-word;
  color: #fff;
}

.userMsg {
  -webkit-animation: animateElement linear 0.2s;
          animation: animateElement linear 0.2s;
  /* animation-iteration-count: 1; */
  margin-top: 5px;
  word-wrap: break-word;
  padding: 0.6em 1em;
  float: right;
  /* margin-right: 0.5em; */
  background: #4285f4;
  border: 1px solid #4285f4;
  color: #fff;
  border-radius: 1.5em;
  margin-bottom: 0.15em;
  font-size: 12px;
  max-width: 75%;
  min-width: 25%;
  line-height: 1.5em;
  margin-left: 100px;
}

.clearfix {
  margin-top: 2px;
  margin-bottom: 2px;
}

.love {
  text-align: center;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  color: rgb(0, 0, 0);
  font-style: italic;
}

.iconic {
  width: 29px;
  height: 29px;
  padding: -23px;
  padding-bottom: 17px;
}
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 80px;
  height: 30px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  overflow: hidden;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 25px;
  height: 25px;
  border-radius: 45px;
  transition: 0.2s;
  padding: 0.2rem;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

.fill a {
  transition: 0.2s ease-in;
  text-decoration: none;
  padding: 0 10px;
}
.fill a:hover {
  background-color: #3c4858;
  color: #ffffff;
  padding: 24px 10px;
}

.home_link__2LSOs:hover {
  box-shadow: 0 0 12px rgba(33, 33, 33, 0.4);
}

.team_bgConfetti__8WwVC
{
    background-image: url(/static/media/landing_confetti.88a169ce.svg);
    background-size: 100%;
    background-position: center;
    background-repeat: repeat-y;
}
.Podcast_container__3BsDH{
    font-family: 'Product Sans', sans-serif;
}
.Podcast_header__1YMu2{
    margin-top: 8vh;
    background-image: url(/static/media/podcastHeader.99dbcf1e.png);
    width: 100vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.Podcast_header__1YMu2 h1{
    padding: 20px 30px;
    font-size: 3.5rem;
    font-weight: 500;
}

.Podcast_seriesList__3nV97{
    padding: 30px 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Podcast_seriesList__3nV97 hr{
    width: 95%;
}

.Podcast_seriesContainer__2x0k6{
    width: 100%;
    max-height: 50vh;
    margin: 50px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

.Podcast_seriesContainer__2x0k6 .Podcast_seriesImage__2fCPu{
    width: 50vh;
    height: 50vh;
    margin-right: 30px;
}

.Podcast_text__2RkaJ{
    height: 100%;
    max-height: 50vh;
    max-width: calc(50vw - 120px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.Podcast_text__2RkaJ h2{
    margin: 0;
    font-size: 3rem;
}

.Podcast_text__2RkaJ p{
    font-size: 1.3rem;
}

.Podcast_episodeList__3a_AM{
    width: 95%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}
.Podcast_episodeContainer__SAAtF{
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    background-color: #bcbcbc37;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.Podcast_episodeName__1_qtp{
    margin: 0 5px;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Podcast_members__3FHn9{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    z-index: 10;
}
.Podcast_memberImage__19H2H{
    width: 25px;
    height: 25px;
    border-radius: 30px;
    margin: 0 4px;
    transition: 0.4s;
}
.Podcast_memberImage__19H2H:hover{
    transform: scale(1.5);
    transition: 0.4s;
}

.Podcast_icon__N6ixJ{
    margin-right: 10px;
}

.Podcast_playerContainer__3FI3D{
    width: 100vw;
    background-color: rgba(196, 196, 196, 0.342);
    -webkit-backdrop-filter: blur(100px);
            backdrop-filter: blur(100px);
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px; 
    z-index: 30;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.Podcast_player__4sATK{
    position: relative;
    margin-bottom: 0;
    width: 50%;
    max-width: 750px;
}
