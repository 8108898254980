.fill a {
  transition: 0.2s ease-in;
  text-decoration: none;
  padding: 0 10px;
}
.fill a:hover {
  background-color: #3c4858;
  color: #ffffff;
  padding: 24px 10px;
}
